export const state = () => ({
  filter: {
    "degree": [],
    "experience": [],
    "financing": [],
    "industry": [],
    "salary": [],
    "strength": [],
    "workplacePolicy": []
  }
})

export const mutations = {
  setFilter(state, data) {
    state.filter = data
  },
  removeFilter(state) {
    state.filter = {}
  }
}

export const actions = {}
