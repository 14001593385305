
export default (context, inject) => {
  const factories = {}

  // 
  function importAll(req) {
    req.keys().forEach((key) => factories[key.replace(/\.\/|\.js/g, '')] = req(key).default(context.$axios));
  }

  importAll(require.context('@/api/', true, /\.js$/));
  // Inject $api
  inject("api", factories);
};