export default axios => ({
  async searchJob(channelName) {
    return await axios.get('/hirey/basedict-service/dict/search/jobClassification?channelName=' + channelName, {
      headers: {
        'x-role': 2
      }
    })
  },
  async searchPlace(place) {
    return await axios.get('/hirey/job-service/google/place/autocomplete?input=' + place, {
      headers: {
        'x-role': 2
      }
    })
  },
  async newDetails(data) {
    return await axios.get('/hirey/job-service/admin/google/place/newDetails', {
      params: data,
      headers: {
        'x-role': 2
      }
    })
  },
  async search(params, data, deviceId) {
    const header = {
      'x-role': 2
    }

    if(deviceId) {
      header['x-deviceId'] = deviceId
    }

    data.header = header

    if(params.cityId === '' || params.cityId === undefined || params.cityId === null) {
      params.cityId = 0
    }
    if(params.qs === undefined || params.qs === null) {
      params.qs = ''
    }

    return await axios.post('/hirey/job-service/admin/jobs/search', data, {
      params,
      headers: header
    })
  },

  async caFilter()  {
    return await axios.get('/hirey/candidate-service/filter/candidate/recommend')
  },
  async getJob(id) {
    return await axios.get('/hirey/job-service/admin/candidates/jobs/' + id)
  }
})
