import Vue from 'vue'
import Vuex from 'vuex'
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import '../assets/scss/element-variables.scss'
import '../assets/scss/common.scss'

import { Picker, Popup } from 'vant';
import 'vant/lib/index.css';

Vue.use(Vuex)
Vue.use(Element, { locale })
Vue.use(Picker)
Vue.use(Popup)
