export default axios => ({
  async uploadFile(data) {
    const config = {
      setFormDataHeaders: true,
    };
    let baseURL = '';
    if ($nuxt.$config.env === 'prod') {
      if ($nuxt.$config.region === 'us') {
        baseURL = 'https://usprod.workandroid.com'
      } else {
        baseURL = 'https://usprod.workandroid.com'
      }
    } else {
      baseURL = 'https://usprod.workandroid.com'
    }
    return await axios.post(baseURL + '/hirey/file/resources', data, config)
  },
})
