import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5a866087 = () => interopDefault(import('../pages/company-homepage.vue' /* webpackChunkName: "pages/company-homepage" */))
const _34b8e1d1 = () => interopDefault(import('../pages/download.vue' /* webpackChunkName: "pages/download" */))
const _afd9414c = () => interopDefault(import('../pages/download_hireyapp.vue' /* webpackChunkName: "pages/download_hireyapp" */))
const _aa1a661e = () => interopDefault(import('../pages/find-jobs.vue' /* webpackChunkName: "pages/find-jobs" */))
const _25e1662e = () => interopDefault(import('../pages/hirey-app-reviews.vue' /* webpackChunkName: "pages/hirey-app-reviews" */))
const _19d7348a = () => interopDefault(import('../pages/hirey-recommend.vue' /* webpackChunkName: "pages/hirey-recommend" */))
const _4f4b214c = () => interopDefault(import('../pages/hirey-reviews.vue' /* webpackChunkName: "pages/hirey-reviews" */))
const _6e213394 = () => interopDefault(import('../pages/hireydownload.vue' /* webpackChunkName: "pages/hireydownload" */))
const _6efdde93 = () => interopDefault(import('../pages/hireystatement.vue' /* webpackChunkName: "pages/hireystatement" */))
const _5771c637 = () => interopDefault(import('../pages/homepage.vue' /* webpackChunkName: "pages/homepage" */))
const _64a61028 = () => interopDefault(import('../pages/jobseeker/index.vue' /* webpackChunkName: "pages/jobseeker/index" */))
const _096eacf2 = () => interopDefault(import('../pages/recruiter.vue' /* webpackChunkName: "pages/recruiter" */))
const _3d3a740c = () => interopDefault(import('../pages/webhirey.vue' /* webpackChunkName: "pages/webhirey" */))
const _f0d6bf9c = () => interopDefault(import('../pages/webhireyrecommend.vue' /* webpackChunkName: "pages/webhireyrecommend" */))
const _474daf01 = () => interopDefault(import('../pages/jobseeker/search/index.vue' /* webpackChunkName: "pages/jobseeker/search/index" */))
const _2e9a51e5 = () => interopDefault(import('../pages/blog/list/_pageNum.vue' /* webpackChunkName: "pages/blog/list/_pageNum" */))
const _97e3afac = () => interopDefault(import('../pages/jobseeker/search/_query.vue' /* webpackChunkName: "pages/jobseeker/search/_query" */))
const _570ad5c0 = () => interopDefault(import('../pages/apply/_id.vue' /* webpackChunkName: "pages/apply/_id" */))
const _95cf66d4 = () => interopDefault(import('../pages/blog/_id.vue' /* webpackChunkName: "pages/blog/_id" */))
const _0aae03a2 = () => interopDefault(import('../pages/job/_id.vue' /* webpackChunkName: "pages/job/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/company-homepage",
    component: _5a866087,
    meta: {"name":"Homepage"},
    name: "company-homepage"
  }, {
    path: "/download",
    component: _34b8e1d1,
    meta: {"name":"Download"},
    name: "download"
  }, {
    path: "/download_hireyapp",
    component: _afd9414c,
    meta: {},
    name: "download_hireyapp"
  }, {
    path: "/find-jobs",
    component: _aa1a661e,
    meta: {},
    name: "find-jobs"
  }, {
    path: "/hirey-app-reviews",
    component: _25e1662e,
    meta: {"name":"reviews","props":{}},
    name: "hirey-app-reviews"
  }, {
    path: "/hirey-recommend",
    component: _19d7348a,
    meta: {},
    name: "hirey-recommend"
  }, {
    path: "/hirey-reviews",
    component: _4f4b214c,
    meta: {"name":"reviews","props":{}},
    name: "hirey-reviews"
  }, {
    path: "/hireydownload",
    component: _6e213394,
    meta: {},
    name: "hireydownload"
  }, {
    path: "/hireystatement",
    component: _6efdde93,
    meta: {},
    name: "hireystatement"
  }, {
    path: "/homepage",
    component: _5771c637,
    meta: {},
    name: "homepage"
  }, {
    path: "/jobseeker",
    component: _64a61028,
    meta: {},
    name: "jobseeker"
  }, {
    path: "/recruiter",
    component: _096eacf2,
    meta: {},
    name: "recruiter"
  }, {
    path: "/webhirey",
    component: _3d3a740c,
    meta: {"name":"WebBC","fetchOnServer":true},
    name: "webhirey"
  }, {
    path: "/webhireyrecommend",
    component: _f0d6bf9c,
    meta: {"name":"WebBC","fetchOnServer":true},
    name: "webhireyrecommend"
  }, {
    path: "/jobseeker/search",
    component: _474daf01,
    meta: {"name":"index"},
    name: "jobseeker-search"
  }, {
    path: "/blog/list/:pageNum?",
    component: _2e9a51e5,
    meta: {},
    name: "blog-list-pageNum"
  }, {
    path: "/jobseeker/search/:query",
    component: _97e3afac,
    meta: {"name":"query"},
    name: "jobseeker-search-query"
  }, {
    path: "/apply/:id?",
    component: _570ad5c0,
    meta: {"name":"apply"},
    name: "apply-id"
  }, {
    path: "/blog/:id?",
    component: _95cf66d4,
    meta: {},
    name: "blog-id"
  }, {
    path: "/job/:id?",
    component: _0aae03a2,
    meta: {},
    name: "job-id"
  }, {
    path: "/",
    component: _474daf01,
    meta: {"name":"index"},
    name: "recruiter_1"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
