const md5 = require('md5')
const base64 = require('base-64');
const CryptoJS = require('crypto-js')
import UAParser from 'ua-parser-js'

function encrypt(word) {
  var key = CryptoJS.enc.Utf8.parse('AAAAx82vPHkDKb284d7NkjFoNcKWBBBB')
  var iv = CryptoJS.enc.Utf8.parse('AAAAGq0YQK2QUlMc')
  var srcs = CryptoJS.enc.Utf8.parse(word)
  var encrypted = CryptoJS.AES.encrypt(
    srcs, key,
    { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  )
  return encrypted.toString()
}
function decrypt(srcs) {
  var key = CryptoJS.enc.Utf8.parse('AAAAx82vPHkDKb284d7NkjFoNcKWBBBB')
  var iv = CryptoJS.enc.Utf8.parse('AAAAGq0YQK2QUlMc')
  // var srcs = CryptoJS.enc.Utf8.parse(word)
  var decrypted = CryptoJS.AES.decrypt(
    srcs, key,
    { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  )
  return CryptoJS.enc.Utf8.stringify(decrypted)
}
export default function ({ $message, app, $axios, redirect, store, $notify, $config }, inject) {
  const parser = new UAParser();
  const ua = parser.getResult()
  const PREFIX = $config.PREFIX || 'hy'
  $axios.onRequest(config => {
    try{
      // hacky patch
      config.url = config.url.replace('/hirey/', `/${PREFIX}/`)
      if(config.url?.indexOf('/hirey/') > -1) {
        // let [url, params = ''] = config.url.split('?')
        // let [prefix, apiUrl] = url.split(`/${PREFIX}/`)
        // let serviceUrl = apiUrl.split('/')[0]
        // serviceUrl = md5(PREFIX + serviceUrl).slice(0, 5)
        // apiUrl = apiUrl.split('/').slice(1).join('/')
        // apiUrl = base64.encode(encrypt(apiUrl))
        // url = `/${PREFIX}/${serviceUrl}/${apiUrl}`
        // config.url = `${url}${params ? '?' + params : ''}`

        config.headers.whereareyou = 'ca'
        if (typeof config.data === 'string') {
          config.data = base64.encode(encrypt(config.data))
        } else {
          config.data = base64.encode(encrypt(JSON.stringify(config.data)))
        }
        let [url, params = ''] = config.url.split('?')
        let [prefix, apiUrl] = url.split(`/${PREFIX}/`)
        let serviceUrl = apiUrl.split('/')[0]
        serviceUrl = md5(PREFIX + serviceUrl).slice(0, 5)
        apiUrl = apiUrl.split('/').slice(1).join('/')
        apiUrl = base64.encode(encrypt(apiUrl))
        url = `/${PREFIX}/${serviceUrl}/${apiUrl}`
        config.url = `${url}${params ? '?' + base64.encode(encrypt(params)) : ''}`
      }
      // const state = app.store.state
      $axios.setHeader('x-timestamp', Date.now())
      $axios.setHeader('x-os', 'webapp')
      $axios.setHeader('X-AppVersion', app.$config.appVersion)

      if (process.client) {
        $axios.setHeader('x-deviceId', md5(window.navigator?.userAgent || ''))
      }
      if(process.server) {
        if(config.data && config.data.header) {
          const h = config.data.header
          for(let key in h) {
            if(key) {
              $axios.setHeader(key, h[key])
            }
          }
        }
      }
      config?.data?.header && delete config.data.header
      $axios.setHeader('x-bundleid', 'com.buildidid.app')
      $axios.setHeader('X-Brand', ua.device.vendor || ua.os.name)
      $axios.setHeader('X-Model', ua.device.model || ua.os.version)
      if(config.setFormDataHeaders) {
        delete config.headers.common['Content-type']
      } else {
        // $axios.setHeader('Content-type', 'application/json;charset=utf-8')
      }
      $axios.setHeader('x-region', 'us')
      // $axios.setHeader('x-uid', '9e8e1e13afca4693ad5a6931d01d8')
      // $axios.setHeader('x-idtoken', 'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiOWU4ZTFlMTNhZmNhNDY5M2FkNWE2OTMxZDAxZDgiLCJjcmVhdGVUaW1lIjoxNjc2ODc1NTcxMjEzLCJpYXQiOjE2NzY4NzU1NzEsImlzcyI6ImFwcF9pc3N1ZXIifQ.mTQuEBnrCVP-5F4xmPZd8GbAXSoizb33PKalyyRyp5A')

      // if (state.user.token) {
      //   config.headers['X-Uid'] = state.user.userInfo?.uid || state.user.userInfo?.roleInfo?.uid
      //   config.headers['X-IDToken'] = state.user.token
      //   $axios.setHeader('X-IDToken', state.user.token)
      //   // $axios.setHeader('region', region)
      //   $axios.setHeader('x-region', 'us')
      //   $axios.setHeader('X-Role', 1)
      // } else {
      //   $axios.setHeader('x-region', 'us')
      //   $axios.setHeader('region', 'us')
      // }
    } catch(e) {
      console.log("onRequestError: ")
      console.error(e)
    }
  })

  // // kick user, redirect to login page
  // const kickOff = () => {
  //   const state = app.store.state
  //   const regionId = state?.user?.userInfo?.regionId
  //   store.commit('user/removeState')
  //   app.router.push({
  //     name: 'login-us',
  //     params: {
  //       showDialog: true
  //     }
  //   })
  // }
  //
  // inject('kickOff', kickOff)
  //
  // $axios.onResponse(async response => {
  //   try{
  //     if (response.status === 200) {
  //       const data = response.data
  //       if (data instanceof Blob) {
  //         return data
  //       } else if (data instanceof ArrayBuffer) {
  //         return data
  //       } else {
  //         if (response.config?.url?.indexOf('/hirey/') >= 0 && response.data.superdata) {
  //           try{
  //             response.data = JSON.parse(decrypt(base64.decode(response.data.superdata) ), function(k,v) {if(k.startsWith('CA-')){this[k.slice(3)]=v;return} return v})
  //             data = response.data
  //             // console.log(data)
  //           } catch (e) {
  //             console.error(e)
  //           }
  //         }
  //       }
  //       if (data?.errMsg?.msg && (data.errMsg.msg.indexOf('Hirey') >= 0 || data.errMsg.msg.indexOf('hirein') >= 0)) {
  //         data.errMsg.msg = ''
  //       }
  //       if (data.code === 2000) {
  //         if (Object.prototype.isPrototypeOf(data?.extra) && JSON.stringify(data?.extra) != '{}') {
  //           return {list: data.data, extra: data?.extra}
  //         }
  //         return data.data
  //       } else if (data.code === 20005) {
  //         const originalRequest = response.config
  //         const result = await store.dispatch('user/refreshToken')
  //         if (result) {
  //           return $axios(originalRequest)
  //         } else {
  //           $message.error('The certificate has expired, please log in again')
  //           store.commit('user/removeState')
  //         }
  //       } else if (data.code === 10002 || data.code === 30001) {
  //         return data
  //       } else if (data.code === 20001) {
  //         $notify.warning({
  //           message: `user doesn\'t exist!`,
  //           duration: 0
  //         })
  //         store.commit('user/removeState')
  //       } else if (data.code === 20007 || data.code === 20006) {
  //         $message.error('The certificate has expired, please log in again')
  //         store.commit('user/removeState')
  //       } else if (data.code === 20004) {
  //         $notify.warning({
  //           message: `Dear user, your recent activity goes against our Hirey community guideline and your account will be restricted temporarily.`,
  //           duration: 0
  //         })
  //         store.commit('user/removeState')
  //       } else if (data.code === 20008 || data.code === 20009) {
  //         if (data.errMsg.msg) {
  //           $notify.warning({
  //             message: data.errMsg.msg,
  //             duration: 0
  //           })
  //         }
  //         store.commit('user/removeState')
  //       } else if (data.code === 30019) {
  //         // redirect and remove dialog when rejected
  //
  //         // $notify.warning({
  //         //   message: `The review information has not passed the review, please resubmit the information in the app`,
  //         //   duration: 0
  //         // })
  //         // store.commit('user/removeState')
  //         // await store.dispatch('user/updateUserInfo')
  //         // app.router.push({
  //         //   name: 'under-verification',
  //         //   params: {
  //         //     status: 'reject',
  //         //     skip: true
  //         //   }
  //         // })
  //         kickOff()
  //       } else if (data.code === 30004 || data.code === 40004) {
  //         $notify.warning({
  //           message: `Account exception`,
  //           duration: 0
  //         })
  //         // $message.warning('Account exception')
  //         store.commit('user/removeState')
  //       } else if (data.code === 30020 || data.code === 40009) {
  //         $notify.warning({
  //           message: `User doesn't exist`,
  //           duration: 0
  //         })
  //         store.commit('user/removeState')
  //       } else if (data.code === 5000 || data.code === 10001) {
  //         return data
  //       } else if (data.code === 30011) {
  //         $notify.warning({
  //           message: `already verified, don't upload document again!`,
  //           duration: 0
  //         })
  //         return data
  //       }
  //     } else {
  //       $message.error('An error occurred. Please try again later ')
  //     }
  //     throw new Error(JSON.stringify(response))
  //   } catch (e) {
  //     console.error("onResponseError:")
  //     console.log(e)
  //   }
  // })

  $axios.onError(err => {
    return Promise.reject(err.response)
  })
}
