import Vue from 'vue'
import {log, encode} from 'smith-log'

export default (context) => {
  const {$axios} = context

  Vue.use(log, {
    env: context.app.$config.env,
    router: context.app.router,
    output: context.app.$config.env === 'dev' ? 'console' : 'online',
    store: context.app.store,
    send: function(body, headers) {
      if(Array.isArray(body)) body = encode(body)
      delete headers['X-Timestamp']
      return $axios.post('/hirey/datastatistics/event/bulk',
        body,
        {
          headers: {
            ...headers,
            build_type: context.app.$config.env
          }
        },
      )
    },
    config: () => {
      return new Promise((resolve) => {
        $axios.get('/hirey/datastatistics/event/config').then(res => {
          resolve(res)
        }).catch(err => {
          resolve({})
        })
        // resolve({})
      })
    }
  })
};
